<template>
  <AlertDialog v-model:open="dialog.open" title="멤버 삭제">
    <slot />
    <template #body-description>
      <pre>
        {{ dialog.description }}
      </pre>
    </template>
    <template #footer-buttons>
      <ButtonGroup gap outline>
        <AlertDialogCancel as-child>
          <Button color="slate" variant="outline" class="min-w-[6.125rem]">취소</Button>
        </AlertDialogCancel>
        <Button color="red" class="min-w-[6.125rem]" @click="confirm">삭제</Button>
      </ButtonGroup>
    </template>
  </AlertDialog>
</template>

<script setup lang="ts">
import { useMemberRemoveConfirm } from '~/composables/shared/useMemberRemoveConfirm'

const { dialog, confirm } = useMemberRemoveConfirm()
</script>
